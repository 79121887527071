<template>
  <v-container class="py-0 py-sm-4 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-4">
        <v-card :class="[view === 2 ? 'secondary' : 'grey']" class="d-flex align-center justify-center pa-4 fill-width fill-height font-weight-bold" dark outlined @click.prevent="view = 2">
          <v-icon small class="mr-2">
            mdi-currency-usd-off
          </v-icon>
          ACCURATE [REALTIME]
        </v-card>
      </v-col>
      <v-col cols="6" class="col-sm-4">
        <v-card :class="[view === 0 ? 'secondary' : 'grey']" class="d-flex align-center justify-center pa-4 fill-width fill-height font-weight-bold" dark outlined @click.prevent="view = 0">
          <v-icon small class="mr-2">
            mdi-currency-usd
          </v-icon>
          MONEY IN [SHIPPING]
        </v-card>
      </v-col>
      <v-col cols="6" class="col-sm-4">
        <v-card :class="[view === 1 ? 'secondary' : 'grey']" class="d-flex align-center justify-center pa-4 fill-width fill-height font-weight-bold" dark outlined @click.prevent="view = 1">
          <v-icon small class="mr-2">
            mdi-currency-usd-off
          </v-icon>
          VENDOR & TRANSPORT
        </v-card>
      </v-col>
      <!-- <v-col cols="12" class="col-sm-4">
        <v-card class="d-flex align-center justify-center pa-4 fill-width fill-height primary font-weight-bold" dark outlined @click.prevent="''">
          <v-icon small class="mr-2">
            mdi-currency-usd-off
          </v-icon>
          CREDIT
        </v-card>
      </v-col> -->
      <v-col cols="12" class="pt-4">
        <FinanceMoneyIn v-if="view === 0" />
        <FinanceVendorInvoices v-else-if="view === 1" />
        <FinanceAccurate v-else-if="view === 2" :authorized="accurate.authorized" :authorized-link="accurate.authorizedLink" :branch="accurate.branch" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Finance',
  data: () => ({
    view: 0,
    accurate: {
      authorized: false,
      authorizedLink: '',
      branch: []
    }
  }),
  created () {
    if (this.$store.state.user) {
      if (!this.$role(['manager', 'svp', 'finance'], this.$store.state.user.role)) {
        this.$router.replace('/')
      }
    } else {
      this.$router.replace('/')
    }
  },
  mounted () {
    this.ACCURATE_BRANCH_GET()
  },
  methods: {
    ACCURATE_BRANCH_GET () {
      this.authorized = false
      this.branch = []
      this.accurate.authorizedLink = ''
      this.$store.dispatch('logistic/ACCURATE_BRANCH_GET')
        .then((res) => {
          if (res && res.status && res.data.data.data.response) {
            this.accurate.authorized = true
            this.accurate.branch = res.data.data.data.data.d || []
          } else if (res && res.status) {
            if (res.data.data.url && res.data.data.data === 'Accurate unauthorized!') {
              this.accurate.authorizedLink = res.data.data.url
            }
            this.$store.dispatch('TOAST', { show: true, message: res.data.data.data || 'Failed' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    }
  }
}
</script>
