<template>
  <v-app>
    <Toast />
    <v-navigation-drawer
      v-if="$store.state.user && $store.state.DashHostName !== businessLink"
      v-model="SideBar"
      :mini-variant="true"
      floating
      fixed
      class="secondary abc-drawer"
      dark
    >
      <v-list color="accent" style="padding-top:0px;padding-bottom:0px;">
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon large>
              mdi-view-dashboard
            </v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>

      <v-divider color="accent" />

      <v-list
        nav
        dense
      >
        <v-list-item v-if="$store.state.user.role !== 'finance'" to="/shipping" link class="d-flex justify-center px-0 my-3" @click.prevent="!$store.state.menus.find((r) => r.s === '/shipping') ? $store.commit('MENUS', { v: { s: '/shipping', i: 'mdi-package-variant-closed', n: 'Shipping' } }) : ''">
          <v-list-item-icon>
            <v-icon style="font-size:28px;">mdi-package-variant-closed</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item v-if="$store.state.user.role !== 'operation' && $store.state.user.role !== 'finance'" to="/member" link class="d-flex justify-center px-0 my-3" @click.prevent="!$store.state.menus.find((r) => r.s === '/member') ? $store.commit('MENUS', { v: { s: '/member', i: 'mdi-account-group-outline', n: 'Customer' } }) : ''">
          <v-list-item-icon>
            <v-icon style="font-size:28px;">mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <!-- <v-list-item-title>Shared with me</v-list-item-title> -->
        </v-list-item>
        <v-list-item v-if="$store.state.user.role !== 'operation' && $store.state.user.role !== 'svp'" to="/finance" link class="d-flex justify-center px-0 my-3" @click.prevent="!$store.state.menus.find((r) => r.s === '/finance') ? $store.commit('MENUS', { v: { s: '/finance', i: 'mdi-cash', n: 'Finance' } }) : ''">
          <v-list-item-icon>
            <v-icon style="font-size:28px;">mdi-cash</v-icon>
          </v-list-item-icon>
          <!-- <v-list-item-title>Starred</v-list-item-title> -->
        </v-list-item>
        <v-list-item v-if="$role(['root'], $store.state.user.role) || ($store.state.user.role === 'manager' && !parseInt($store.state.user.store))" to="/store-manager" link class="d-flex justify-center px-0 my-3" @click.prevent="!$store.state.menus.find((r) => r.s === '/store-manager') ? $store.commit('MENUS', { v: { s: '/store-manager', i: 'mdi-domain', n: 'Store Manager' } }) : ''">
          <v-list-item-icon>
            <v-icon style="font-size:28px;">mdi-domain</v-icon>
          </v-list-item-icon>
          <!-- <v-list-item-title>Starred</v-list-item-title> -->
        </v-list-item>
        <v-list-item v-if="$role([], $store.state.user.role)" to="/account-manager" link class="d-flex justify-center px-0 my-3" @click.prevent="!$store.state.menus.find((r) => r.s === '/account-manager') ? $store.commit('MENUS', { v: { s: '/account-manager', i: 'mdi-account-lock-open-outline', n: 'Account Manager' } }) : ''">
          <v-list-item-icon>
            <v-icon style="font-size:28px;">mdi-account-lock-open-outline</v-icon>
          </v-list-item-icon>
          <!-- <v-list-item-title>Starred</v-list-item-title> -->
        </v-list-item>
        <v-divider class="my-3" />
        <v-list-item @click.prevent="DO_LOGOUT()" class="d-flex justify-center px-0 my-3">
          <v-list-item-icon>
            <v-icon style="font-size:28px;">mdi-arrow-right-bold-hexagon-outline</v-icon>
          </v-list-item-icon>
          <!-- <v-list-item-title>Starred</v-list-item-title> -->
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div class="d-flex align-center justify-center secondary d-lg-none" :style="{ left: SideBar ? '0px' : '0px' }" style="z-index:8;top:94vh;position:fixed;width:54px;height:54px;" @click.prevent="SideBar = !SideBar">
      <v-icon color="white">
        {{ SideBar ? 'mdi-close' : 'mdi-menu' }}
      </v-icon>
    </div>
    <Auth v-if="!$store.state.user" />
    <v-main v-else-if="$store.state.DashHostName === businessLink" style="background-color: #D9DEE3;">
    <!-- <v-main v-else-if="$store.state.DashHostName === 'business.anterocargo.id' || $store.state.DashHostName === 'localhost'"> -->
      <div class="main-container dashboard">
        <v-container>
          <BusinessTTKContent :customer="$store.state.user" />
        </v-container>
      </div>
    </v-main>
    <v-main v-else :class="[SideBar ? 'pl-14' : '']" style="background-color: #D9DEE3;">
      <div :class="[SideBar ? '' : 'full']" class="main-appbar d-flex align-center justify-space-between primary">
        <router-link to="/" class="fill-height d-flex align-center px-4 white justify-center" style="width:120px;">
          <img src="/logo150x50.png" style="width:75px;">
        </router-link>
        <div class="py-3 px-4 d-flex align-center">
          <div class="d-flex align-center white rounded-lg caption font-weight-bold px-3 py-2 fill-height">
            <v-icon class="mr-2" style="font-size:20px;" color="black">
              mdi-account
            </v-icon>
            {{ $store.state?.user?.name }}
            <!-- <v-icon small class="ml-3" color="black">
              mdi-chevron-down
            </v-icon> -->
          </div>
        </div>
        <v-row v-if="false" no-gutters>
          <v-col cols="12" class="col-sm-12">
            <v-tabs
              fixed-tabs
              :height="48"
              background-color="bg-business-primary"
              dark
            >
              <v-tabs-slider :color="'secondary'" />
              <v-tab to="/" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-apps
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  {{ MyStoreName }}
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp', 'operation'], $store.state.user.role)" to="/shipping" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-truck-fast
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Shipping
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp'], $store.state.user.role)" to="/member" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-account-group
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Data Client
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp', 'finance'], $store.state.user.role)" to="/finance" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-cash
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Finance
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp'], $store.state.user.role)" to="/store-manager" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-office-building-marker
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Cabang & Vendor
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp'], $store.state.user.role)" to="/account-manager" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-account-cog-outline
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Account Manager
                </span>
              </v-tab>
              <!-- <v-tab v-if="$role([], $store.state.user.role)" to="/features/main-banner" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-newspaper-variant-outline
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Berita / Artikel
                </span>
              </v-tab> -->
              <v-tab @click.prevent="DO_LOGOUT" class="text-capitalize ls-05 red--text white">
                <v-icon small color="red">
                  mdi-power
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Sign Out
                </span>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </div>
      <div class="main-container px-0 px-sm-6">
        <div class="relative fill-width" style="overflow-x:auto;white-space: nowrap;">
          <span :class="[$route.name === 'Home' ? 'active' : '']" class="nav-menu-flex d-inline-flex align-center justify-space-between px-4 py-3 c-pointer" style="opacity:.75;min-width:200px;border-radius: 0px 15px 0px 0px;" @click.prevent="$router.push('/')">
            <v-icon class="mr-2" style="font-size:24px;">
              mdi-view-dashboard
            </v-icon>
            Dashboard
            <v-spacer />
          </span>
          <span v-for="(m) in $store.state.menus" :key="'menus-' + m.s" :class="[$route.name.toLowerCase() === (m.s || '').replace('/', '').replace('-', '') ? 'active' : '']" class="nav-menu-flex ml-2 d-inline-flex align-center justify-space-between px-4 py-3 c-pointer" style="opacity:.75;min-width:220px;border-radius: 0px 15px 0px 0px;" @click.prevent="$router.push(m.s)">
            <v-icon class="mr-2" style="font-size:24px;">
              {{ m.i }}
            </v-icon>
            {{ m.n }}
            <v-spacer />
            <span class="fill-height d-flex align-center" @click.prevent @click.stop>
              <v-icon small class="ic-close" @click="$store.commit('MENUS', { a: 'del', v: { s: m.s } })">
                mdi-close
              </v-icon>
            </span>
          </span>
        </div>
        <v-container fluid style="background-color:#F9F9F9;">
          <router-view/>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    SideBar: true,
    menus: [],
    businessLink: 'business.anterocargo.id'
  }),
  computed: {
    MyStoreName () {
      if (this.$store.state.user) {
        const store = this.$store.state.storeData.find(r => parseInt(r.id) === parseInt(this.$store.state.user.store))
        return store ? (store.code + ' - ' + store.name) : 'Dashboard'
      }
      return 'Dashboard'
    },
    ColorStoreActive () {
      // const storeActive = parseInt(this.$store.state.storeActive)
      const color = 'primary'
      // if (storeActive) {
      //   const store = this.$store.state.storeData.find(r => parseInt(r.id) === storeActive)
      //   if (store) {
      //     if (store.color) {
      //       return store.color
      //     }
      //   }
      //   const index = this.$store.state.storeData.findIndex(r => parseInt(r.id) === storeActive)
      //   switch (index) {
      //     case 0:
      //       color = 'primary'
      //       break
      //     case 1:
      //       color = 'secondary'
      //       break
      //     case 2:
      //       color = 'blue'
      //       break
      //     case 3:
      //       color = 'green'
      //       break
      //     case 4:
      //       color = 'teal'
      //       break
      //     case 5:
      //       color = 'lime darken-1'
      //       break
      //     case 6:
      //       color = 'orange'
      //       break
      //     case 7:
      //       color = 'indigo'
      //       break
      //     default:
      //       break
      //   }
      // }
      return color
    }
  },
  methods: {
    DO_LOGOUT () {
      if (!confirm('Logout ?')) {
        return false
      }
      localStorage.removeItem('t')
      this.$store.commit('USER', null)
      this.$store.commit('MENUS', 'clear')
    }
  }
}
</script>

<style lang="scss">
.nav-menu-flex {
  background-color: #8391A1;
  color: #fff!important;
  >i {
    color: #ffffff!important;
  }
  >button {
    color: #FFFFFF!important;
  }
  &.active {
    background-color: #FFFFFF;
    color: #000!important;
    font-weight: bold!important;
    >i {
      color: #000000!important;
    }
    button {
      display: none;
    }
  }
}
html, a, a:link, a:visited, a:hover, a:active {
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}
html {
  // height: 100vh!important;
  // overflow: hidden!important;
  >body {
    // overflow: hidden!important;
    >.v-application {
      // height: 100vh!important;
      // overflow: auto!important;
      .display-4,
      .display-3,
      .display-2,
      .display-1,
      .headline,
      .title,
      .subtitle-1,
      .subtitle-2,
      .body-1,
      .body-2,
      .caption,
      .overline {
        font-family: 'Poppins', sans-serif!important;
      }
    }
  }
}

.main-appbar {
  position: fixed;
  top: 0px;
  width: calc(100% - 56px);
  z-index: 7;
  height: 56px!important;
  &.full {
    width: 100%;
  }
}
.main-container {
  padding-top: 78px;
  position: relative;
  // overflow-y: auto;
  >.container--fluid {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .05);
  }
}
.v-card {
  &.v-sheet {
    &.v-card--flat {
      &.shadow-lg {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, .05) !important;
      }
    }
  }
}
.c-pointer {
  cursor: pointer!important;
}
.fill-width {
  width: 100%!important;
}
.p-relative {
  position: relative!important;
}
.ls-0 {
  letter-spacing: 0px!important;
}
.ls-05 {
  letter-spacing: .5px!important;
}
.ls-1 {
  letter-spacing: 1px!important;
}
.lh-0 {
  line-height: 100%!important;
}
a {
  text-decoration: none!important;
  color: unset!important;
}
.v-card {
  border-radius: 0px!important;
}
.file-uploader {
  width: 0px;
  height: 0px;
  position: fixed;
  bottom: -100%;
  z-index: -2;
}
.v-tabs {
  .v-slide-group__prev,
  .v-slide-next {
    display: none!important;
  }
}
.theme--dark {
  &.btn-checkout {
    &.v-btn--disabled {
      &.v-btn--has-bg {
        &.v-btn {
          background-color: rgba(0, 0, 0, .1)!important;
          color: rgba(0, 0, 0, .3)!important;
        }
      }
    }
  }
}
.caption {
  letter-spacing: 0px!important;
}
.caption-small {
  font-size: 12px!important;
  letter-spacing: 0px;
}
.v-textarea {
  textarea {
    line-height: 125%!important;
  }
}
a {
  &.disabled {
    pointer-events: none!important;
  }
}

.v-calendar-monthly {
  border-radius: 4px!important;
  // overflow: hidden;
  &.v-calendar-weekly {
    @media only screen and (max-width: 768px) {
      border-radius: 0px!important;
      width: 117%;
      margin-left: -8.5%!important;
      margin-right: -8.5%!important;
      border: solid 0px!important;
    }
  }
  .v-calendar-weekly__head {
    .v-calendar-weekly__head-weekday {
      background-color: #262d44!important;
      padding: 4px 4px 4px 4px;
      font-weight: bold;
      color: #ffffff!important;
      &.v-outside {
        color: #9e959f!important;
      }
      &.v-present {
        color: #fcb068!important;
      }
    }
  }
  .v-calendar-weekly__day {
    min-height: 45px;
    padding: 0px 5px 10px 5px;
    .v-event {
      margin-top: 5px!important;
      width: 100%!important;
      height: 12px!important;
      display: flex!important;
      justify-content: flex-start!important;
      align-items: center!important;
      line-height: unset!important;
      // left: 55%;
      left: 0px;
      border-radius: 20px!important;
      >.booking-counter {
        position: relative;
        padding: 0px!important;
        font-size: .5rem!important;
        width: 100%;
        height: 100%;
        text-align: center;
        display:flex;
        justify-content: center!important;
        align-items: center!important;
        span {
          &.booking-counter-progress {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            background-color: #262d44;
            z-index: -1;
          }
        }
      }
    }
    :nth-child(2) {
      &.v-event {
        // margin-top: -10px!important;
        margin-top: 5px!important;
        margin-bottom: 5px!important;
        &.grey {
          background: transparent!important;
        }
        >.booking-counter {
          span {
            color: #262d44;
            font-size: .7rem;
            font-weight: bold;
            &.booking-counter-progress {
              display: none;
            }
          }
        }
      }
    }
    &.v-present {
      background: #fbede6;
    }
    >.v-calendar-weekly__day-label {
      margin-top: 10px;
      margin-bottom: 0px;
      .v-btn--fab {
        &.v-size--small {
          width: 25px!important;
          height: 25px!important;
        }
        &.primary {
          background-color: #f4700b!important;
          color: #fff!important;
        }
        &.transparent {
          background-color: #e3d9e9!important;
          color: #412a54!important;
        }
      }
    }
    .v-event-more {
      display: none;
    }
  }
}
table {
  thead {
    &.v-data-table-header {
      >tr {
        // background-color: #262d44!important;
        background-color: rgba(39, 44, 91, .8);
        // background: linear-gradient(145deg, rgba(22,56,73,1) 30%, rgb(148, 207, 39) 100%)!important;
        >th {
          height: 100px!important;
          border-bottom: 0px!important;
          >i.theme--light,
          >span {
            color: #fff!important;
          }
          >i.theme--light {
            font-size: .8rem!important;
            margin-left: 5px!important;
            margin-top: -1px!important;
          }
          >.v-data-table__checkbox  {
            >.v-input--selection-controls__input {
              >i {
                &.theme--light {
                  &.v-icon {
                    color: #ffffff!important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.caption-small {
  font-size: .7rem!important;
}

@media screen {
  // .dialog-printed {
  //   display: none;
  // }
}
.print-only {
  display: none;
}
#dialogPrintPDF {
  &.row {
    margin: 0px!important;
    .col {
      padding: 2px!important;
      padding-bottom: 4px!important;
      &.col-left {
        padding-right: 2px!important;
        border-right: dashed 1px #000!important;
        border-bottom: dashed 1px #000!important;
      }
      &.col-right {
        padding-left: 8px!important;
        border-bottom: dashed 1px #000!important;
      }
    }
  }
}
@mixin print-area {
  body {
    font-size: 10px!important;
  }
  .print-only {
    display: block;
  }
  .v-application {
    width:100%!important;
    height:auto!important;
    >.v-application--wrap {
      display: none!important;
    }
  }
  .v-dialog__content {
    height: auto!important;
    max-height: unset!important;
    width: 100%!important;
    position: relative!important;
    align-items: flex-start!important;
    justify-content: start!important;
    .v-dialog {
      max-height: unset!important;
      &.dialog-printed {
        visibility:hidden!important;
        border-radius: 0px!important;
        margin: 0px!important;
        width:100%!important;
        .print-content {
          >.container {
            min-width: unset!important;
          }
        }
        >.v-card,
        >.v-card * {
          visibility: visible;
          overflow: visible !important;
        }
        >.v-card {
          box-shadow: none!important;
          position:absolute;
          left:0;
          top:0;
        }
        .no-print {
          display: none!important;
          visibility:hidden;
        }
        .abc-logo {
          height: 45px!important;
        }
        canvas {
          height: 120px!important;
          width: 120px!important;
        }
        .text-h5 {
          font-size: 12px!important;
        }
        .v-divider {
          display: none;
        }
        .primary {
          &.white--text {
            &.pa-2 {
              padding: 0px!important;
              color: #000!important;
            }
          }
        }
        .my-2 {
          margin-top: 0px!important;
          margin-bottom: 0px!important;
        }
        .row {
          margin: 0px!important;
          .col {
            padding: 2px!important;
            padding-bottom: 4px!important;
            &.col-left {
              padding-right: 2px!important;
              border-right: dashed 1px #000!important;
              border-bottom: dashed 1px #000!important;
            }
            &.col-right {
              padding-left: 8px!important;
              border-bottom: dashed 1px #000!important;
            }
          }
        }
        .pa-4 {
          padding: 4px!important;
        }
      }
    }
  }
}

html {
  &.print-area {
    @include print-area;
  }
}
@media print {
  html {
    &.overflow-y-hidden {
      overflow: auto!important;
    }
  }
  @include print-area;
}
// table {
//   &.table-print {
//     border-spacing: 0px;
//     border-collapse: collapse;  /* <--- add this so all the internal <td>s share adjacent borders  */
//     border: 1px solid black!important;
//     tr {
//       border: 1px solid rgba(0, 0, 0, .5)!important;
//       border-collapse: collapse;
//       border-spacing: 0px;
//       td {
//         border: 1px solid rgba(0, 0, 0, .5)!important;
//       }
//     }
//   }
// }
@media (min-width: 1264px) {
  .container {
    max-width: 100%!important;
  }
}

.bg-business-primary {
  background: #262d44;
  background: linear-gradient(145deg, rgba(22,56,73,1) 30%, rgb(148, 207, 39) 100%);
}
.bg-business-secondary {
  background: #262d44;
  background: linear-gradient(145deg, rgba(22,56,73,1) 30%, rgb(251, 140, 0) 100%);
}
.business-tabs-items {
  >.v-window__container {
    height: 100%!important;
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  height: 40px!important;
}

.main-container {
  &.dashboard {
    padding: 0px!important;
    height: 100%!important;
    background-color: #f0f0f0;
    >.container {
      max-width: 1000px!important;
      height: 100%!important;
      padding: 0px!important;
      >.v-card {
        height: 100%!important;
        .v-toolbar__title {
          .mdi-chevron-down {
            display: none;
          }
        }
        .v-toolbar__items {
          >button {
            display: none;
            &.dashboard-logout {
              display: block!important;
            }
          }
        }
      }
      .confirm-delivery-and-update {
        display: none!important;
      }
    }
  }
}
.bg-glass {
  /* From https://css.glass */
  background: rgba(254, 254, 254, 0.46);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(254, 254, 254, 0.66);
}
.abc-drawer {
  .v-navigation-drawer__content {
    .v-list {
      &.v-sheet {
        >[role="listitem"] {
          >.v-list-item__icon {
            margin-right: 0px!important;
            margin-left: 0px!important;
          }
        }
      }
    }
  }
}
</style>
