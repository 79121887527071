<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="col-sm-6">
        <v-select
          v-model.number="CabangSelected"
          :disabled="!$role('', $store.state.user.role)"
          :items="[{ id: -1, name: '- Semua cabang -' }].concat($store.state.storeData.filter(r => !parseInt(r.type)))"
          item-text="name"
          item-value="id"
          outlined
          solo
          flat
          class="body-2 c-pointer rounded-xl font-weight-bold"
          hide-details
          label="Cabang"
        />
      </v-col>
      <v-col cols="12" class="col-sm-6">
        <v-menu
          ref="DatesSelectedEl"
          v-model="DatesSelected.menu"
          :return-value.sync="DatesSelected.dates"
          left
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="DatesSelected.dates"
              label="Date range"
              append-icon="mdi-calendar-outline"
              class="body-2 c-pointer rounded-xl font-weight-bold"
              readonly
              outlined
              solo
              flat
              hide-details
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="DatesSelected.dates = []"
            />
          </template>
          <v-date-picker
            v-model="DatesSelected.dates"
            no-title
            light
            class="force-light-font"
            range
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="align-stretch">
      <v-col cols="12" class="col-sm-6">
        <v-card class="pa-4 fill-height shadow-lg rounded-lg" flat color="white">
          <div class="font-weight-bold">
            Total Branch Achievement
          </div>
          <apexchart ref="chartStore" width="100%" height="250" type="bar" :options="optionsStore" :series="seriesStore"></apexchart>
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-6">
        <v-card class="pa-4 fill-height shadow-lg rounded-lg" flat color="white">
          <div class="font-weight-bold">
            TTK
          </div>
          <apexchart ref="chartSelling" width="100%" height="250" type="area" :options="optionsSelling" :series="seriesSelling"></apexchart>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="12" class="col-sm-4">
        <v-card class="elevation-0 rounded">
          <v-card-title class="text-body-2 font-weight-bold">
            <div class="fill-width d-flex align-center justify-space-between">
              TTK PUBLISH
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text>
            OK
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col cols="12" class="col-sm-6">
        <v-card class="elevation-0 rounded">
          <v-card-title class="justify-center text-body-2 font-weight-bold">
            MANIFEST
          </v-card-title>
          <v-divider />
          <v-card-text>
            INPROGRESS
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-6">
        <v-card class="elevation-0 rounded">
          <v-card-title class="justify-center text-body-2 font-weight-bold">
            DISPATCH
          </v-card-title>
          <v-divider />
          <v-card-text>
            INPROGRESS
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <!-- <v-container v-if="false "class="py-0">
    <div>
      <DispatchMain />
    </div>
    <v-row v-if="false" no-gutters class="justify-center">
      <v-col cols="12" class="col-sm-8 pa-4 justify-center text-center text-h4 mb-6">
        Total <strong>{{ parseInt(CountTotal || 0).toLocaleString() }}</strong> WNI sedang berada di Luar Negeri
        <v-divider class="mt-8" />
      </v-col>
      <v-col v-if="true" cols="12" class="col-sm-8 pb-10">
        <Map />
      </v-col>
      <v-col cols="12" class="col-sm-8">
        <v-row no-gutters>
          <v-col v-for="(ka, iKa) in $store.state.kbri_area" :key="'kbri-area-' +iKa" cols="12" class="col-sm-4 pa-2">
            <v-card class="pa-4 rounded-lg">
              <div class="d-flex fill-width justify-space-between font-weight-bold align-center">
                {{ ka.name }}
                <span class="d-flex align-center">
                  {{ CountSubTotal(GetDaerah(ka)) }}
                  <v-icon class="ml-2">
                    mdi-account-multiple
                  </v-icon>
                </span>
              </div>
              <v-divider class="my-3" />
              <div class="d-flex flex-wrap">
                <v-chip v-for="(da, iDa) in GetDaerah(ka)" :key="'' + iKa + '-' + iDa" :class="[parseInt(da.total) ? 'indigo white--text' : '']" class="mr-1 my-1" x-small>
                  {{ da.name }} ({{ da.total }})
                </v-chip>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogWeb.show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="rounded-0">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogWeb.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Content Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              class="font-weight-bold"
              @click="WebsiteProcess()"
            >
              Update
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="dialogWeb.data" class="py-6">
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-home
                </v-icon>
                HOME PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.home_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home Title"
              />
              <v-text-field
                v-model="dialogWeb.data.home_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home Caption"
              />
              <v-text-field
                v-model="dialogWeb.data.home_headline"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home - Headline Title"
                :append-icon="dialogWeb.data.home_headline_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('home_headline_image')"
              />
              <v-textarea
                v-model="dialogWeb.data.home_headline_description"
                outlined
                rows="4"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home - Headline Description"
              />
              <v-text-field
                v-model="dialogWeb.data.home_event_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home - Event Title"
                :append-icon="dialogWeb.data.home_event_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('home_event_image')"
              />
              <v-textarea
                v-model="dialogWeb.data.home_event_description"
                outlined
                rows="3"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home - Event Description"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-newspaper-variant-outline
                </v-icon>
                NEWS PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_news_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="News Title"
                :append-icon="dialogWeb.data.page_news_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_news_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_news_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="News Caption"
              />
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-calendar-clock
                </v-icon>
                EVENT PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_event_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Event Title"
                :append-icon="dialogWeb.data.page_event_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_event_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_event_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Event Caption"
              />
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-image-multiple
                </v-icon>
                GALLERY PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_gallery_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Gallery Title"
                :append-icon="dialogWeb.data.page_gallery_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_gallery_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_gallery_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Gallery Caption"
              />
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-account
                </v-icon>
                ACCOUNT PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_account_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Account Title"
                :append-icon="dialogWeb.data.page_account_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_account_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_account_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Account Caption"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-phone
                </v-icon>
                CONTACT PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_contact_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Contact Title"
                :append-icon="dialogWeb.data.page_contact_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_contact_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_contact_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Contact Caption"
              />
              <v-text-field
                v-model="dialogWeb.data.page_contact_address"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Contact Address"
              />
              <v-text-field
                v-model="dialogWeb.data.page_contact_email"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Contact Email"
              />
              <v-text-field
                v-model="dialogWeb.data.page_contact_whatsapp"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Contact Whatsapp (62)"
                prefix="62"
                @keypress="$NumberOnly"
              />
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-information
                </v-icon>
                ABOUT PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_about_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About Title"
                :append-icon="dialogWeb.data.page_about_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_about_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_about_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About Caption"
              />
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                # CONTENT - ABOUT
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_about_content_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Content Title"
              />
              <v-textarea
                v-model="dialogWeb.data.page_about_content_description"
                outlined
                rows="3"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Content Description"
              />
              <v-text-field
                v-model="dialogWeb.data.page_about_content_visi"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Visi Content Title"
                :append-icon="dialogWeb.data.page_about_content_visi_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_about_content_visi_image')"
              />
              <v-textarea
                v-model="dialogWeb.data.page_about_content_visi_description"
                outlined
                rows="3"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Visi Content Description"
              />
              <v-text-field
                v-model="dialogWeb.data.page_about_content_misi"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Misi Content Title"
                :append-icon="dialogWeb.data.page_about_content_misi_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_about_content_misi_image')"
              />
              <v-textarea
                v-model="dialogWeb.data.page_about_content_misi_description"
                outlined
                rows="3"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Mist Content Description"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                # CONTENT - TERMS & CONDITIONS
              </div>
              <v-divider class="mb-4" />
              <v-textarea
                v-model="dialogWeb.data.page_terms"
                outlined
                rows="20"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Content - Terms & Conditions"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                # CONTENT - FREQUENTLY ASKED QUESTION
              </div>
              <v-divider class="mb-4" />
              <v-textarea
                v-model="dialogWeb.data.page_faq"
                outlined
                rows="20"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Content - Frequently Asked Question"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container> -->
</template>

<script>
let timeoutReload = null
export default {
  name: 'Home',
  data: vm => ({
    DatesSelectedEl: null,
    DatesSelected: {
      menu: false,
      dates: []
    },
    CabangSelected: 0,
    optionsSelling: {
      theme: {
        palette: 'palette2'
      },
      chart: {
        id: 'chartSelling'
      },
      tooltip: {
        fillSeriesColor: true,
        theme: 'light',
        y: {
          formatter: function (val, series) {
            if (series.seriesIndex === 1) {
              return (val || 0).toLocaleString() + ' TTK'
            }
            return vm.$price(val)
          }
        }
      },
      stroke: {
        show: true,
        width: 2,
        dashArray: 0,
        // curve: 'smooth',
        colors: '#262D5B'
        // curve: ['smooth', 'straight', 'stepline']
      },
      xaxis: {
        type: 'datetime',
        categories: [],
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'normal',
            colors: '#777777'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (val, opts) {
            return vm.$price(val)
          },
          style: {
            fontSize: '10px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'normal',
            colors: ['#777777']
          }
        }
      },
      dataLabels: {
        enabled: false,
        style: {
          fontSize: '9px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'normal',
          colors: ['#FF0000']
        },
        formatter: function (val, series) {
          if (series.seriesIndex === 1) {
            return (val || 0).toLocaleString() + ' TTK'
          }
          return vm.$price(val)
        }
      }
    },
    seriesSelling: [{
      name: 'Selling',
      data: []
    }, {
      name: 'TTK Publish',
      data: []
    }],
    optionsStore: {
      theme: {
        palette: 'palette2'
      },
      chart: {
        id: 'chartStore'
      },
      colors: ['#E57925'],
      tooltip: {
        fillSeriesColor: true,
        theme: 'light'
      },
      stroke: {
        show: false,
        width: 2,
        dashArray: 0,
        curve: 'smooth'
        // curve: ['smooth', 'straight', 'stepline']
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          style: {
            fontSize: '10px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'normal',
            colors: '#777777'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (val, opts) {
            return vm.$price(val)
          },
          style: {
            fontSize: '10px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'normal',
            colors: ['#777777']
          }
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'normal',
          colors: ['#FFFFFF']
        },
        formatter: function (val, opts) {
          return vm.$price(val)
        }
      }
    },
    seriesStore: [{
      name: 'Branch Name',
      data: []
    }],
    loadingImageUpload: false,
    dialogWeb: {
      show: false,
      data: null
    }
  }),
  computed: {
  },
  watch: {
    'DatesSelected.dates': {
      handler (v) {
        if (v && v.length === 2) {
          this.$refs.DatesSelectedEl.save(v)
          this.DatesSelected.menu = false
          this.Init()
        } else {
          this.Init()
        }
      }
    },
    CabangSelected: {
      immediate: true,
      handler () {
        this.Init()
      }
    },
    $route: {
      immediate: true,
      handler (to, from) {
        if (to) {
        }
      }
    }
  },
  mounted () {
    this.dialogWeb.show = false
    this.dialogWeb.data = null
    this.$store.dispatch('STORE')
  },
  methods: {
    async Init () {
      if (timeoutReload) {
        clearTimeout(timeoutReload)
      }
      timeoutReload = setTimeout(() => {
        this.LoadGraphStore()
        this.LoadGraphSelling()
      }, 500)
    },
    async LoadGraphSelling () {
      const categories = []
      const series = []
      const seriesTTK = []
      await this.$store.dispatch('GRAPH_SELLING', `?store=${this.CabangSelected}${this.GetDates()}`)
        .then((res) => {
          if (res && res.status && res.data && res.data.data) {
            const data = this.$sortArray((res.data.data || []).map((r) => {
              r.dt = new Date(r.dt)
              r.dtString = new Date(r.dt).toISOString()
              return r
            }), 'dt')
            for (let x = 0; x < data.length; x++) {
              const d = data[x]
              categories.push(d.dtString)
              series.push(parseFloat(d.total) || 0)
              seriesTTK.push(parseFloat(d.total_ttk) || 0)
            }
          }
        })
      await this.$refs.chartSelling.updateOptions({
        xaxis: {
          categories: categories || []
        },
        series: [{
          name: 'Selling',
          data: series || []
        }, {
          name: 'TTK Publish',
          data: seriesTTK || []
        }]
      })
    },
    async LoadGraphStore () {
      const categories = []
      const series = []
      await this.$store.dispatch('GRAPH_STORE', `?store=${this.CabangSelected}${this.GetDates()}`)
        .then((res) => {
          if (res && res.status && res.data && res.data.data) {
            const data = res.data.data || []
            for (let x = 0; x < data.length; x++) {
              const d = data[x]
              categories.push(d.store_name)
              series.push(parseFloat(d.total) || 0)
            }
          }
        })
      await this.$refs.chartStore.updateOptions({
        xaxis: {
          categories: categories || []
        },
        series: [{
          name: 'Amount',
          data: series || []
        }]
      })
    },
    GetDates () {
      const fromTo = this.DatesSelected.dates || []
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    },
    CountSubTotal (d) {
      let total = 0
      for (let a = 0; a < d.length; a++) {
        total += (parseInt(d[a].total) || 0)
      }
      return total
    },
    GetDaerah (n) {
      const data = []
      if (n) {
        if (n.sub_area) {
          const areas = n.sub_area.split('|-|')
          for (let a = 0; a < areas.length; a++) {
            const area = areas[a] ? areas[a].split('::t') : []
            if (area.length) {
              if (parseInt(area[0])) {
                data.push({
                  id: parseInt(area[0]),
                  area_id: parseInt(n.id),
                  area_name: n.name,
                  name: area[1],
                  code: area[2],
                  total: area[3]
                })
              }
            }
          }
        }
      }
      return data
    },
    WebsiteProcess () {
      const data = this.dialogWeb.data
      data.page_contact_whatsapp = '62' + (data.page_contact_whatsapp || '')
      this.$store.dispatch('WEBSITE_PROCESS', data)
        .then((res) => {
          if (res.status) {
            this.dialogWeb.data = null
            this.dialogWeb.show = false
            this.$store.dispatch('TOAST', { show: true, message: 'Updated!' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    OpenContentManager () {
      this.$store.dispatch('WEBSITE_GET')
        .then((res) => {
          if (res.status) {
            delete res.data.data.galleries
            delete res.data.data.news
            delete res.data.data.banner
            this.dialogWeb.data = Object.assign({}, res.data.data)
            this.dialogWeb.show = true
          }
        })
    },
    UploadImage (key) {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e, key)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event, key) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: 'website'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                $.dialogWeb.data[key] = resUpload.data.data.secure_url
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
