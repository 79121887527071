<template>
  <v-container class="py-0 py-sm-2 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-4 mb-4 mb-sm-0">
        <v-card class="d-flex align-center justify-center pa-4 fill-width fill-height primary font-weight-bold rounded-xl" dark outlined @click.prevent="ACCOUNT_SHOW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Tambah Data
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-8 white">
        <div class="pl-0 pl-sm-4 pb-0">
          <v-text-field
            v-model="tableSearch"
            outlined
            hide-details
            class="rounded-xl fill-width"
            placeholder="Search (Account Name, Email, Phone)"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [500]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div>
              #ID/{{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="py-2 d-flex align-center justify-space-between">
              <div>
                {{ item.name }} - {{ item.phone }}
              </div>
              <v-spacer v-if="item.role === 'marketing'" />
              <v-chip v-if="item.role === 'marketing'" small class="font-weight-bold" color="secondary">
                {{ $price(item.sales_handling_price) }} ({{ item.sales_handling_count }})
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <div class="text-capitalize">
              {{ item.role }} - {{ item.store_name }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.status) === 100 ? 'red' : parseInt(item.status) === 99 ? 'yellow darken-2' : parseInt(item.status) === 1 ? 'green' : 'grey'" outlined dark small>
                {{ parseInt(item.status) === 100 ? 'Deleted' : parseInt(item.status) === 99 ? 'Blocked' : parseInt(item.status) === 1 ? 'Active' : 'Not Active' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role([], $store.state.user.role)" depressed small dark color="orange" class="rounded-0 text-capitalize" @click.prevent="ACCOUNT_SHOW(item)">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogAccount.show"
      persistent
      scrollable
      max-width="320"
    >
      <v-card v-if="dialogAccount.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogAccount.data.id ? 'Update Data' : 'Tambah Data' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-text-field
            v-model="dialogAccount.data.name"
            outlined
            dense
            hide-details
            label="Name"
            class="mb-4"
          />
          <v-text-field
            v-model="dialogAccount.data.email"
            outlined
            dense
            hide-details
            label="Email"
            class="mb-4"
          />
          <v-text-field
            v-model="dialogAccount.data.phone"
            outlined
            dense
            hide-details
            label="No.HP / Whatsapp"
            class="mb-4"
            @keypress="$NumOnly"
          />
          <v-text-field
            v-model="dialogAccount.data.password"
            outlined
            dense
            hide-details
            label="Temporary Password"
            type="password"
            class="mb-4"
          />
          <v-select
            v-model="dialogAccount.data.role"
            :items="[
              { name: 'Super Admin', value: 'admin' },
              { name: 'Manager', value: 'manager' },
              { name: 'SVP', value: 'svp' },
              { name: 'Operation', value: 'operation' },
              { name: 'Finance', value: 'finance' },
              { name: 'Pickup Courier', value: 'courier' },
              { name: 'Sales / Marketing', value: 'marketing' }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Account Role"
          />
          <v-select
            v-if="dialogAccount.data.role !== 'admin' && dialogAccount.data.role !== 'root'"
            v-model.number="dialogAccount.data.store"
            :items="$store.state.storeData"
            :item-text="(v) => {
              return (parseInt(v.type) ? 'Vendor - ' : (parseInt(v.id) ? 'Cabang - ' : 'Pusat -')) + v.name
            }"
            item-value="id"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Cabang/Vendor"
          />
          <v-select
            v-model.number="dialogAccount.data.status"
            :items="[
              { name: 'Active', value: 1 },
              { name: 'Not Active', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Account Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogAccount.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="$role([], $store.state.user.role)"
            color="green darken-1"
            text
            @click="ACCOUNT_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
let searchInterval = null
export default {
  name: 'AccountManager',
  data: () => ({
    headers: [
      {
        text: 'ACCOUNT ID',
        value: 'id',
        sortable: false
      },
      { text: 'Name', value: 'name', sortable: true },
      // { text: 'Store Located', value: 'store_name', sortable: false },
      { text: 'Role', value: 'role', sortable: true },
      { text: 'Email', value: 'email', sortable: true },
      { text: 'Status', value: 'status', align: 'center', sortable: true },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 500,
    tableSearchStatus: null,
    options: {
      sortBy: ['created'],
      sortDesc: 'DESC',
      itemsPerPage: 500,
      page: 1
    },
    dialogAccount: {
      show: false,
      data: null
    }
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    options () {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.ACCOUNTS_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    storeActive (v) {
      if (v) {
        this.ACCOUNTS_GET(this.tableSearch)
      }
    }
  },
  created () {
    if (this.$store.state.user) {
      console.log(this.$store.state.user.role)
      if (!this.$role(['manager', 'svp'], this.$store.state.user.role)) {
        this.$router.replace('/')
      }
    } else {
      this.$router.replace('/')
    }
  },
  mounted () {
    this.ACCOUNTS_GET()
  },
  methods: {
    ACCOUNT_SHOW (account) {
      if (account) {
        this.dialogAccount.data = Object.assign({}, account)
      } else {
        this.dialogAccount.data = {
          id: null,
          name: '',
          email: '',
          phone: '',
          password: '',
          store: 0,
          status: 1,
          role: 'admin'
        }
      }
      this.dialogAccount.show = true
    },
    ACCOUNTS_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? ('&page=' + page) : '') + '&limit=' + (parseInt(itemsPerPage) || 25) + ((sortBy && sortBy.length) ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('account/ACCOUNTS_GET', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '')).then((res) => {
        if (res.status) {
          this.table = res.data.data.data
          this.tableTotal = parseInt(res.data.data.total) || 0
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    ACCOUNT_PROCESS () {
      const processedAccount = Object.assign({}, this.dialogAccount.data)
      if (!processedAccount.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan nama yang valid!' })
        return false
      }
      if (!this.$validateEmail(processedAccount.email)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Email tidak valid!' })
        return false
      }
      if (processedAccount.role === 'admin' || processedAccount.role === 'root') {
        processedAccount.store = 0
      } else if (!(parseInt(processedAccount.store) === 0 || parseInt(processedAccount.store) > 0)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Cabang / vendor belum dipilih!' })
        return false
      }
      processedAccount.phone = processedAccount.phone ? this.$reformatPhone(processedAccount.phone) : ''
      if (this.$store.state.user.role !== 'root' && this.$store.state.user.role !== 'admin') {
        if (processedAccount.role === 'root' || processedAccount.role === 'admin') {
          this.$store.dispatch('TOAST', { show: true, message: 'Role ini tidak bisa diotorisasi!' })
          return false
        }
      }

      this.$store.dispatch('account/ACCOUNT_PROCESS', processedAccount)
        .then((res) => {
          if (res.status) {
            if (res.data.data.response) {
              this.ACCOUNTS_GET(this.tableSearch)
              this.dialogAccount.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedAccount.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    }
  }
}
</script>
