<template>
  <div>
    <v-btn class="ml-4 rounded-pill" depressed @click.prevent="OpenApproval">
      SVP Approval
    </v-btn>
    <v-dialog
      v-model="dialog.show"
      persistent
      scrollable
      max-width="750"
    >
      <v-card class="px-4">
        <v-card-title class="flex-column body-1 align-center text-uppercase font-weight-bold">
          <span class="subtitle-2">- SVP APPROVAL -</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-4" style="height: 90vh;">
          <v-row no-gutters>
            <v-col :class="[tab === 0 ? 'green darken-2 white--text' : '']" cols="4" class="text-center c-pointer rounded-pill" @click.prevent="ACCOUNTS_GET(), tab = 0">
              Customer
            </v-col>
            <v-col :class="[tab === 1 ? 'green darken-2 white--text' : '']" cols="4" class="text-center c-pointer rounded-pill" @click.prevent="SHIPPING_GET(), tab = 1">
              TTK
            </v-col>
            <v-col :class="[tab === 2 ? 'green darken-2 white--text' : '']" cols="4" class="text-center c-pointer rounded-pill" @click.prevent="MANIFEST_GET(), tab = 2">
              Manifest
            </v-col>
          </v-row>
          <v-divider class="mt-4 mb-2" />
          <div v-if="tab === 0">
            <div v-for="(c, iC) in customer.data" :key="'customer-approval-' + iC" class="d-flex align-center fill-width justify-space-between my-2">
              <div class="w-full pr-4">
                {{ c.name }}, {{ c.phone }}
                <div class="text-caption">
                  {{ c.address }}
                </div>
              </div>
              <v-btn size="x-small" x-small color="green" class="white--text" depressed @click.prevent="Approve(c)">
                Approve
              </v-btn>
            </div>
          </div>
          <div v-else-if="tab === 1">
            <div v-for="(item, iC) in ttk.data" :key="'ttk-approval-' + iC" class="d-flex align-center fill-width justify-space-between my-2">
              <div class="w-full pr-4">
                <div class="py-2">
                  <div class="d-flex align-center text-uppercase">
                    <v-chip class="mr-1 white--text" x-small :color="parseInt(item.ttk_type) === 2
                            ? 'purple'
                            : parseInt(item.ttk_type) === 1
                              ? 'orange'
                              : parseInt(item.ttk_type) === -1
                                ? 'brown'
                                : 'blue'"
                    >
                      {{
                        parseInt(item.ttk_type) === 2
                          ? 'Project Logistik'
                          : parseInt(item.ttk_type) === 1
                            ? 'Project Distribusi'
                            : parseInt(item.ttk_type) === -1
                              ? 'Charter'
                              : 'Retail'
                      }}
                    </v-chip>
                    <span class="font-weight-bold caption">
                      {{ item.ttk || '-' }}
                    </span>
                  </div>
                  <span v-if="parseInt(item.ttk_type) === 2 && parseInt(item.project)" class="d-flex align-center caption purple--text text-truncate fill-width text-uppercase font-weight-bold">
                    PROJECT : {{ item.project_name || '-' }}
                  </span>
                  <div v-else class="d-flex align-center caption secondary--text text-uppercase font-weight-bold text-truncate fill-width">
                    <v-icon small class="mr-1" color="secondary">
                      mdi-package
                    </v-icon>
                    {{ item.product_name || '-' }}
                  </div>
                  <div v-if="item.vehicle_name" class="d-flex align-center caption secondary--text text-uppercase font-weight-bold text-truncate fill-width">
                    <v-icon small class="mr-1" color="secondary">
                      mdi-truck-fast
                    </v-icon>
                    {{ item.vehicle_plat_number || '#' }} - {{ item.vehicle_name }}
                  </div>
                  <div class="d-flex align-center caption orange--text font-weight-bold">
                    <v-icon small class="mr-1" color="orange">
                      mdi-information
                    </v-icon>
                    {{ item.weight_chargeable || '~' }}KG, {{ item.shipping_package_quantity_sum }} {{ (parseInt(item.product_is_unit) ? 'UNIT' : 'ITEMS') }} in {{ item.shipping_package_quantity }} PKG
                  </div>
                </div>
              </div>
              <div class="fill-width py-2 text-caption">
                <div class="caption">
                  Bill To : {{ item.customer_billto_detail || '-' }}
                </div>
                <div>
                  From: {{ item.shipping_sender_name || '-' }}, {{ item.shipping_origin_city_name }} {{ item.shipping_origin_city_type }}
                </div>
                <div>
                  To {{ item.shipping_name || '-' }}: {{ item.shipping_destination_city_name }} {{ item.shipping_destination_city_type }}
                </div>
              </div>
              <div class="d-flex flex-column">
                <div v-if="parseInt(item.ttk_type) < 1">
                  <div v-if="item.master_count_type === 'UNIT'" class="d-flex justify-end py-2">
                    {{ $price(item.master_price_final) }} (1 UNIT)
                  </div>
                  <div v-else class="d-flex flex-column align-end py-2">
                    {{ $price(item.price_final) }}
                    <div class="d-flex items-center text-caption">
                      {{ parseInt(item.product_is_unit) ? '[UNIT]' : ('[' + item.weight_chargeable + ' KG]') }}
                    </div>
                  </div>
                </div>
                <div class="d-flex align-center">
                  <v-btn size="x-small" x-small color="green" class="white--text" depressed @click.prevent="ApproveTTK(item)">
                    Approve
                  </v-btn>
                  <v-btn size="x-small" x-small color="green" class="white--text ml-2" icon @click.prevent="$emit('open', item)">
                    <v-icon>
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="tab === 2" class="text-center">
            <v-data-table
              dense
              :headers="manifest.headers"
              :items="manifest.data.filter((f) => {
                return parseInt(f.dispatch) && !f.departure && !parseInt(f.status_approval)
              })"
              :server-items-length="manifest.total"
              items-per-page.sync="25"
              :options.sync="manifest.options"
              :footer-props="{
                itemsPerPageOptions: [100]
              }"
              :loading="manifest.loading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.id`]="{ item }">
                <div class="py-2">
                  <div class="d-flex align-center text-uppercase">
                    <span class="font-weight-bold caption">
                      [{{ item.manifest_arrival ? 'IN' : (item.manifest_departure ? 'OUT' : '-') }}]
                      MANIFEST/{{ item.store_origin_code }}/{{ item.store_destination_code }}{{ item.vendor_code ? ('/' + item.vendor_code) : '' }}/{{ item.id }}
                    </span>
                  </div>
                  <span v-if="item.manifest_ext_id" class="d-flex align-center caption secondary--text font-weight-bold">
                    <v-icon small class="mr-1">
                      mdi-ticket-confirmation-outline
                    </v-icon>
                    <div style="line-height: 120%;">
                      <div class="grey--text text--darken-2 font-weight-regular" style="font-size: .6rem;">
                        VENDOR AWB/RESI :
                      </div>
                      {{ item.manifest_ext_id || '-' }}
                    </div>
                  </span>
                  <div class="d-flex align-center caption">
                    <v-icon small class="mr-1">
                      mdi-calendar-outline
                    </v-icon>
                    {{ $localDT(item.created, 'datetime') }}
                  </div>
                  <div class="d-flex align-center">
                    <v-icon small class="mr-1">
                      mdi-note-text-outline
                    </v-icon>
                    {{ item.notes || '-' }}
                  </div>
                  <div v-if="((item.vendor_name || item.courier_detail || '-').trim() && (item.vendor_name || item.courier_detail || '-').trim() !== ',') || item.vendor_name" class="d-flex flex-column py-2">
                    {{ (item.vendor_name || item.courier_detail || '-').trim() === ',' ? '' : (item.vendor_name || item.courier_detail || '-').trim() }}
                    <div v-if="item.vendor_name" class="caption grey--text">
                      {{ $price(item.price) }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between caption">
                    <div v-if="parseInt(item.unit_quantity_sum)">
                      <v-chip x-small class="font-weight-bold">
                        {{ item.unit_weight || '~' }}KG, {{ item.unit_quantity_sum }} UNIT in {{ item.unit_quantity }} PKG
                      </v-chip>
                    </div>
                    <v-divider v-if="parseInt(item.unit_quantity_sum) && parseInt(item.unitnon_quantity_sum)" vertical />
                    <div v-if="parseInt(item.unitnon_quantity_sum)">
                      <v-chip x-small class="font-weight-bold">
                        {{ item.unitnon_weight || '~' }}KG, {{ item.unitnon_quantity_sum }} ITEM in {{ item.unitnon_quantity }} PKG
                      </v-chip>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.shipping_total`]="{ item }">
                <div class="text-center">
                  {{ parseInt(item.shipping_total) }}
                </div>
              </template>
              <template v-slot:[`item.store_origin`]="{ item }">
                <div class="d-flex flex-column py-2">
                  {{ item.type === 'courier' ? 'COURIER -' : '' }}{{ item.store_origin_name || '-' }}
                  <div class="caption">
                    {{ item.store_origin_city || '-' }}
                  </div>
                  <div class="caption d-flex align-center">
                    {{ item.manifest_departure ? $localDT(item.manifest_departure, 'datetime') : '~' }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.store_destination`]="{ item }">
                <div class="d-flex flex-column py-2">
                  {{ item.type === 'courier' ? 'COURIER -' : '' }}{{ item.store_destination_name || '-' }}
                  <div class="caption">
                    {{ item.store_destination_city || '-' }}
                  </div>
                  <div class="caption d-flex align-center">
                    <span v-if="item.manifest_arrival">
                      {{ $localDT(item.manifest_arrival, 'datetime') }}
                    </span>
                    <v-chip v-else-if="item.type === 'courier'" x-small color="secondary">
                      On Process by Courier
                    </v-chip>
                    <span v-else>
                      ~
                    </span>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.option`]="{ item }">
                <div class="d-flex flex-column align-end" style="min-width: 100px;">
                  <v-btn v-if="(parseInt(item.dispatch)) && !item.departure && !parseInt(item.status_approval)" small dark depressed color="primary" class="rounded-pill my-2 font-weight-bold" @click.prevent="MANIFEST_PROCESS('APPROVE', item)">
                    APPROVE MANIFEST
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="$emit('close', true), dialog.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    tab: 0,
    dialog: {
      show: false,
      data: null
    },
    loading: false,
    customerQuery: null,
    customer: {
      data: [],
      total: 0
    },
    ttkQuery: null,
    ttk: {
      data: [],
      total: 0
    },
    manifest: {
      headers: [
        { text: 'No', value: 'id', sortable: false },
        { text: 'TTK', value: 'shipping_total', sortable: false },
        { text: 'Asal', value: 'store_origin', sortable: false },
        { text: 'Tujuan', value: 'store_destination', sortable: false },
        { text: 'Opsi', value: 'option', sortable: false }
      ],
      options: {
        sortBy: ['id'],
        sortDesc: 'DESC',
        itemsPerPage: 25,
        page: 1
      },
      loading: false,
      data: [],
      total: 0
    }
  }),
  watch: {
    'dialog.show': {
      immediate: true,
      handler (v) {
        if (v) {
          this.ACCOUNTS_GET()
          this.SHIPPING_GET()
          this.MANIFEST_GET()
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    MANIFEST_PROCESS (ACT, item) {
      const data = Object.assign(item, {
        status_approval: 1
      })
      this.manifest.loading = true
      this.$store.dispatch('logistic/MANIFEST_PROCESS', data)
        .then((res) => {
          this.manifest.loading = false
          if (res.status) {
            this.MANIFEST_GET()
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong!' })
          }
        })
    },
    MANIFEST_GET () {
      this.manifest.data = []
      this.manifest.total = 0
      this.manifest.loading = true
      const query = `?q=&status=0&store=${this.$store.state.user.store}&type=&page=1&limit=25&sortby=id&sort=DESC`
      this.$store.dispatch('logistic/MANIFEST_GET', query).then((res) => {
        if (res.status) {
          this.manifest.data = res.data.data.data || []
          this.manifest.total = parseInt(res.data.data.total) || 0
        }
        this.manifest.loading = false
      })
    },
    OpenApproval () {
      this.dialog.show = true
    },
    Approve (c) {
      this.loading = true
      this.$store.dispatch('customer/CUSTOMER_PROCESS_BY_ADMIN', {
        id: c.id,
        approved: this.$store.state.user.id
      })
        .then((res) => {
          this.loading = false
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Approved!' })
            // this.ACCOUNTS_GET()
            const findIndex = this.customer.data.findIndex(r => parseInt(r.id) === parseInt(c.id))
            if (findIndex > -1) {
              this.customer.data.splice(findIndex, 1)
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    ApproveTTK (item) {
      this.loading = true
      this.$store.dispatch('logistic/SHIPPING_PROCESS', {
        id: item.id,
        status: item.status
      })
        .then((res) => {
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Approved!' })
            const findIndex = this.ttk.data.findIndex(r => parseInt(r.id) === parseInt(item.id))
            if (findIndex > -1) {
              this.ttk.data.splice(findIndex, 1)
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    ACCOUNTS_GET (q) {
      this.$store.dispatch('customer/CUSTOMER_SEARCH', `?q=${this.customerQuery || ''}&approval=1`)
        .then((res) => {
          if (res.status) {
            this.customer.data = res.data.data.data || []
            this.customer.total = parseInt(res.data.data.total) || 0
          } else {
            this.customer.data = []
            this.customer.total = 0
          }
          this.loading = false
        })
    },
    SHIPPING_GET () {
      this.$store.dispatch('logistic/SHIPPING_GET', `?q=${this.ttkQuery || ''}&store=${this.$store.state.user.store}&page=0&limit=100&status=-1`)
        .then((res) => {
          if (res.status) {
            this.ttk.data = res.data.data.data || []
            this.ttk.total = parseInt(res.data.data.total) || 0
          } else {
            this.ttk.data = []
            this.ttk.total = 0
          }
        })
    }
  }
}
</script>
