<template v-if="$store.state.user">
  <v-container v-if="$role(['manager', 'svp'], $store.state.user.role)" class="py-0 py-sm-4 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-3 pb-4 pb-sm-0">
        <v-card class="d-flex align-center font-weight-bold justify-center pa-4 fill-width fill-height primary rounded-xl" dark outlined @click.prevent="ACCOUNT_SHOW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Tambah Data
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-5">
        <div class="pl-4 pl-sm-8 pr-4 pb-4 pb-sm-0">
          <v-text-field
            v-model="tableSearch"
            outlined
            hide-details
            class="rounded-xl"
            placeholder="Nama, email, telepon ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-2">
        <div class="px-4 pb-4 pb-sm-0">
          <v-select
            v-model.number="customerFilter"
            :items="[
              { name: 'Tampilkan Semua', value: -1 },
              { name: 'Default', value: 0 },
              { name: 'Business', value: 1 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            hide-details
            class="rounded-xl"
            label="Tipe Customer"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-2">
        <div class="px-4 pb-4 pb-sm-0">
          <v-autocomplete
            v-model.number="salesFilter"
            :items="dataSalesFilter"
            item-value="id"
            item-text="user_detail"
            outlined
            hide-details
            clearable
            class="rounded-xl"
            label="Sales"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div>
              <div class="d-flex align-center text-uppercase">
                <v-icon class="mr-1">
                  mdi-barcode
                </v-icon>
                <span class="font-weight-bold caption">
                  ABC/{{ ('000000' + item.id).slice(-6) }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex flex-column py-2">
              <div class="d-flex fill-width align-center justify-space-between text-capitalize">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.stay_reason`]="{ item }">
            <div class="py-2">
              {{ item.stay_reason }}
              <div>
                {{ item.stay_name || '-' }}, {{ item.stay_data1 || '-' }}, {{ item.stay_data2 || '-' }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.sales`]="{ item }">
            <div class="text-center">
              {{ item.sales_detail }}
            </div>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.status) ? 'blue darken-2' : 'orange darken-2'" outlined dark small>
                {{ parseInt(item.status) ? 'Business ' : 'Default' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.approved`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.approved) ? 'green darken-2' : 'grey darken-2'" outlined dark small @click.prevent="$role(['manager', 'svp'], $store.state.user.role) ? Approve(item) : null">
                {{ parseInt(item.approved) ? 'Approved ' : ($role(['manager', 'svp'], $store.state.user.role) ? 'Approve' : 'Waiting approval') }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager', 'svp'], $store.state.user.role)" depressed small dark color="orange" class="text-capitalize" @click.prevent="ACCOUNT_SHOW(item)">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
              <!-- <v-btn text small dark color="red" class="text-capitalize" @click.prevent="DeleteCustomer(item)"
              >
                Hapus
              </v-btn> -->
            </div>
          </template>
          <template v-slot:footer>
            <div class="pt-2 pb-2 px-4 px-md-8 white fill-width">
              <v-divider class="mt-2 mb-3" />
              <div class="d-flex justify-end flex-column flex-sm-row">
                <div class="d-flex align-center font-weight-bold">
                  Total : {{ tableTotal }}
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogAccount.show"
      persistent
      scrollable
      max-width="420"
    >
      <v-card v-if="dialogAccount.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogAccount.data.id ? 'Update Data' : 'Tambah Data Baru' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: calc(100vh - 200px)">
          <div class="d-flex fill-width justify-center pt-4 pb-8">
            <v-img
              :src="dialogAccount.data.img || '/logo.png'"
              :lazy-src="dialogAccount.data.img || '/logo.png'"
              :aspect-ratio="1"
              max-width="100"
              class="rounded-circle grey lighten-4 mr-4"
            />
          </div>
          <v-divider class="my-4" />
          <v-row no-gutters>
            <v-col cols="12" class="">
              <v-text-field
                v-model="dialogAccount.data.name"
                outlined
                dense
                hide-details
                label="Nama Lengkap (*)"
                class="mb-4 "
              />
              <v-text-field
                v-model="dialogAccount.data.email"
                outlined
                dense
                hide-details
                label="Email"
                class="mb-4 "
              />
              <v-text-field
                v-model="dialogAccount.data.phone"
                label="No Telepon (*)"
                dense
                outlined
                color="secondary"
                hide-details
                class="mb-4 "
                @keypress="$NumberOnly"
              />
              <v-textarea
                v-model="dialogAccount.data.address"
                outlined
                rows="4"
                dense
                hide-details
                label="Alamat"
                class="mb-4 "
              />
              <v-autocomplete
                v-model.number="dialogAccount.data.city_id"
                :name="Math.random()"
                :search-input.sync="areaquery"
                :items="areas"
                item-value="city_id"
                item-text="area_name"
                outlined
                dense
                hide-details
                label="Area (Kota, Provinsi)"
                class="mb-4"
                autocomplete="disabled"
              />
              <v-select
                v-model.number="dialogAccount.data.type"
                :items="[
                  { name: 'Default', value: 0 },
                  { name: 'Business', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4 "
                label="Type Customer"
              />
              <!-- <v-text-field
                v-model.number="dialogAccount.data.type_price"
                outlined
                dense
                hide-details
                label="Discount Price (Percent)"
                suffix="%"
                class="mb-4 "
              /> -->
              <v-select
                v-model.number="dialogAccount.data.status"
                :items="[
                  { name: 'Non Aktif', value: 0 },
                  { name: 'Aktif', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class=""
                label="Status"
              />
            </v-col>
            <v-col cols="12">
              <v-divider class="my-4" />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model.number="dialogAccount.data.sales"
                :name="Math.random()"
                :search-input.sync="dataSalesQuery"
                :items="dataSales"
                item-value="id"
                item-text="user_detail"
                outlined
                dense
                hide-details
                label="- Cari Sales -"
                clearable
                autocomplete="disabled"
              />
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <div class="mt-2 mb-0 pa-4 orange lighten-2 rounded-lg">
            <v-text-field
              v-model="dialogAccount.data.password"
              label="Password Sementara / Reset Password"
              outlined
              dense
              hide-details
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="orange darken-1"
            text
            class="text-capitalize"
            @click="dialogAccount.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <!-- <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="DeleteCustomer(dialogAccount.data)"
          >
            Hapus
          </v-btn>
          <v-spacer /> -->
          <v-btn
            color="green darken-1"
            text
            class="text-capitalize"
            @click="ACCOUNT_PROCESS()"
          >
            Process
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
let searchInterval = null
let searchAreaTimeout = null
let timeOutSales = null
export default {
  name: 'Customer',
  data: () => ({
    menuDateBirthday: false,
    menuDatePassportExpire: false,
    menuDateArrival: false,
    menuDateVisaFrom: false,
    menuDateVisaTo: false,
    birthdayMenu: false,
    activePicker: null,
    customerFilter: -1,
    areaquery: '',
    areas: [],
    menu: false,
    dates: [],
    tableSearchCoordinator: 0,
    headers: [
      {
        text: 'USER ID',
        value: 'id'
      },
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'No Telepon', value: 'phone' },
      { text: 'Sales', value: 'sales' },
      { text: 'Approved', value: 'approved' },
      { text: 'Type', value: 'type' },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: -1,
    options: {
      sortBy: ['created'],
      sortDesc: 'DESC',
      itemsPerPage: 25,
      page: 1
    },
    dialogAccount: {
      show: false,
      data: null
    },
    dataSalesQuery: '',
    dataSales: [],
    dataSalesFilter: null,
    salesFilter: [],
    loading: false
  }),
  watch: {
    dataSalesQuery (v) {
      if (!v) {
        return false
      }
      if (timeOutSales) {
        clearTimeout(timeOutSales)
      }
      timeOutSales = setTimeout(() => {
        this.SALES_GET()
      }, 300)
    },
    areaquery (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areas.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaTimeout) {
        clearTimeout(searchAreaTimeout)
      }
      searchAreaTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchAreaTimeout)
      }, 300)
    },
    customerFilter (v) {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.ACCOUNTS_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    tableSearchCoordinator () {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    options () {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    salesFilter () {
      this.ACCOUNTS_GET(this.tableSearch)
    }
  },
  created () {
    if (this.$store.state.user) {
      if (!this.$role(['manager', 'svp'], this.$store.state.user.role)) {
        this.$router.replace('/')
      }
    } else {
      this.$router.replace('/')
    }
  },
  mounted () {
    this.SALES_GET(true)
  },
  methods: {
    SALES_GET (isForFilter) {
      let q = '?q=' + (this.dataSalesQuery || '')
      if (isForFilter) {
        q = ''
      }
      this.$store.dispatch('account/ACCOUNTS_GET', q).then((res) => {
        if (res.status) {
          if (isForFilter) {
            this.dataSalesFilter = (res.data.data.data || []).filter(r => r.role === 'marketing')
          } else {
            this.dataSales = (res.data.data.data || []).filter(r => r.role === 'marketing')
          }
        }
      })
    },
    DeleteCustomer (d) {
      if (d) {
        if (d.name) {
          if (!confirm('Yakin akan hapus data ?')) {
            return false
          }
          this.$store.dispatch('customer/CUSTOMER_DELETE', d.id)
            .then((res) => {
              this.ACCOUNTS_GET(this.tableSearch)
              this.dialogAccount.show = false
            })
        }
      }
    },
    ACCOUNT_SHOW (account) {
      if (account) {
        account.password = ''
        if (account.area_name) {
          this.areaquery = account.area_name || ''
        }
        if (account.sales_detail) {
          this.dataSalesQuery = account.sales_detail
        }
        this.dialogAccount.data = Object.assign({}, account)
      } else {
        this.dialogAccount.data = {
          type: 0,
          type_price: 0,
          name: '',
          phone: '',
          email: '',
          address: '',
          city_id: 0,
          province_id: '',

          img: '',
          password: '',
          rauth: '',
          verified_email: 0,
          verified_phone: 0,
          verified_email_code: '',
          verified_phone_code: '',
          fcm_ids: '',
          status: 1,
          role: 'customer',
          notes: ''
        }
      }
      this.dialogAccount.show = true
    },
    areaGet (q, id) {
      this.$store.dispatch('AREA_GET', q + (parseInt(id) ? '&id=' + id : '') + '&nonKecamatan=1').then((res) => {
        if (res.status) {
          this.areas = res.data.data || []
        }
      })
    },
    Approve (c) {
      this.loading = true
      this.$store.dispatch('customer/CUSTOMER_PROCESS_BY_ADMIN', {
        id: c.id,
        approved: this.$store.state.user.id
      })
        .then((res) => {
          this.loading = false
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Approved!' })
            this.ACCOUNTS_GET(this.tableSearch)
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    ACCOUNTS_GET (q) {
      const status = this.tableSearchStatus > -1 ? this.tableSearchStatus : ''
      const type = this.customerFilter > -1 ? this.customerFilter : ''
      const sales = parseInt(this.salesFilter) ? `&sales=${this.salesFilter}` : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('customer/CUSTOMER_SEARCH', query + (q ? ('&q=' + q) : '') + sales + ('&coordinator=' + (parseInt(this.tableSearchCoordinator) || 0)) + (parseInt(type) === 0 || parseInt(type) > 0 ? '&type=' + type : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : ''))
        .then((res) => {
          if (res.status) {
            this.table = res.data.data.data
            this.tableTotal = parseInt(res.data.data.total) || 0
          } else {
            this.table = []
            this.tableTotal = 0
          }
          this.tableLoading = false
        })
    },
    ACCOUNT_PROCESS () {
      if (this.loading) {
        this.$store.dispatch('TOAST', { show: true, message: 'Mohon tunggu!' })
        return false
      }
      const processedAccount = Object.assign({}, this.dialogAccount.data)
      if (!processedAccount.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama tidak boleh kosong!' })
        return false
      } else if (processedAccount.name.length < 3) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukkan nama yang valid!' })
        return false
      }

      // if (!this.$validatePhone(processedAccount.phone)) {
      //   this.$store.dispatch('TOAST', { show: true, message: 'No telepon tidak valid!' })
      //   return false
      // }
      // processedAccount.phone = processedAccount.phone ? this.$reformatPhone(processedAccount.phone) : ''

      if (!processedAccount.address) {
        this.$store.dispatch('TOAST', { show: true, message: 'Alamat tidak valid!' })
        return false
      }
      if (!parseInt(processedAccount.city_id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Area belum dipilih!' })
        return false
      } else {
        const area = this.areas.find(r => parseInt(r.city_id) === parseInt(processedAccount.city_id))
        processedAccount.province_id = area ? (parseInt(area.province_id) || 0) : 0
      }

      processedAccount.role = 'customer'
      if (!processedAccount.password) {
        delete processedAccount.password
      }
      this.loading = true
      this.$store.dispatch('customer/CUSTOMER_PROCESS_BY_ADMIN', processedAccount)
        .then((res) => {
          this.loading = false
          if (res.status) {
            if (res.data.data.status) {
              this.ACCOUNTS_GET(this.tableSearch)
              this.dialogAccount.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedAccount.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    GetDates () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    }
  }
}
</script>
